<template>
  <CartInfoCard
    :infoValue="value"
    :text="text"
    :hideEdit="true"
    icon="$editcalendar"
    :editable="editable"
    @edit="edit"
  />
</template>
<script>
import CartInfoCard from "./CartInfoCard";
export default {
  name: "CartInfoTimeslotCard",
  props: {
    shippingAddress: { type: Object, required: true },
    timeslot: { type: Object, required: true },
    editable: { type: Boolean, default: false }
  },
  components: { CartInfoCard },
  computed: {
    value() {
      if (this.timeslot) {
        let date = this.$dayjs(this.timeslot.date);
        return date.format("ddd D MMMM") + " | " + this.timeslot.name;
      }
      return this.$t("navbar.noTimeslot");
    },
    text() {
      return this.$t(
        "navbar.address." +
          this.shippingAddress.addressTypeId +
          ".timeslotButtonTitle"
      );
    }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
