<template>
  <v-layout vertical>
    <CheckoutCompleteProfile
      v-if="profileLevel < minProfileLevel && isCheckout"
    />
    <CheckoutConfirmEmail
      v-if="!confirmed && isCheckout"
      :key="orderCart.refreshTime"
    />
    <DeliveryFee
      v-if="isCheckout"
      :showList="true"
      :deliveryFee="deliveryFee"
      class="mb-4 order-last order-sm-first"
    />

    <GiftCode
      v-if="isCheckout"
      class="gift-code mb-4 order-last order-sm-2"
      :giftCertificatesReceived="giftCertificates"
      @addGiftCode="addGiftCode"
      @sendGiftCode="sendGiftCode"
    />
    <v-card class="pa-3 w-100" flat>
      <h3 class="secondary--text text-lighten-1 mb-3 cart-summary-header">
        {{ $t("cartSummary.header") }}
      </h3>
      <v-card-text class="text-body-2 grey lighten-2 rounded-md black--text">
        <div
          class="d-flex justify-space-between align-top text-body-2  font-weight-bold"
        >
          <span>{{ $t("cartSummary.totalPrice") }}</span>
          <span>
            {{ $n(netTotal, "currency") }}
          </span>
        </div>
        <div class=" d-flex justify-space-between align-top text-caption">
          <span>{{ $t("cartSummary.totalPriceVariable") }}</span>
          <span>{{ $n(orderCart.totalPriceVariable, "currency") }}</span>
        </div>

        <!-- v-divider class="my-3" />
        <div
          class=" d-flex justify-space-between align-top mt-3 text-caption font-weight-bold"
        >
          <span>{{ $t("cartSummary.packageTotal") }}</span>
          <span>{{ $n(orderCart.packageTotal, "currency") }}</span>
        </div> -->

        <v-divider class="my-3" />
        <div
          v-if="deliveryServiceId == 2"
          class="d-flex justify-space-between align-top mt-3 text-caption font-weight-bold"
        >
          <span>{{ $t("cartSummary.deliveryFee") }}</span>
          <span>{{ $n(orderCart.deliveryFee, "currency") }}</span>
        </div>

        <div
          class=" d-flex justify-space-between align-top"
          v-if="isOrderDetailPage"
        >
          <strong class="summary-text">{{
            $t("cartSummary.totalBagsPrice")
          }}</strong>
          <strong class="summary-value">{{
            $n(orderCart.packageTotal, "currency")
          }}</strong>
        </div>
        <v-divider class="my-3" v-if="orderCart.orderPackages" />
        <div
          class="d-flex justify-space-between align-top mt-3 text-caption font-weight-bold"
          v-for="orderPackage in orderCart.orderPackages"
          :key="orderPackage.orderPackageId"
        >
          <span
            >{{ orderPackage.package.descr }} <br />
            ({{ orderPackage.quantity }}x{{
              $n(orderPackage.package.cost, "currency")
            }})
          </span>
          <span>{{ $n(orderPackage.grossTotal, "currency") }}</span>
        </div>

        <v-divider
          class="my-3"
          v-if="orderCart.giftCertificates && orderCart.giftCertificates.length"
        />
        <div
          v-for="gift in orderCart.giftCertificates"
          :key="gift.giftCertificateId"
        >
          <div class="d-flex justify-space-between align-center">
            <div>
              <v-btn
                v-if="isCheckout"
                icon
                @click="removeGiftCode(gift.userGiftCertificateId)"
                right
              >
                <v-icon color="primary" x-small>$delete</v-icon>
              </v-btn>

              <span class="summary-text">{{ gift.description }} </span>
            </div>
            <span class="summary-text">-{{ giftAmount(gift) }}</span>
          </div>
        </div>
        <div
          class="d-flex justify-space-between align-top mt-3 text-caption font-weight-bold"
          v-for="gift in orderCart.giftCertificatesToGenerate"
          :key="gift.giftCertificateId"
        >
          <span
            class="summary-text px-2 white--text text-uppercase secondary"
            >{{ gift.description }}</span
          >
          <span class="summary-value" v-if="gift.giftTotal">
            {{ $n(gift.giftTotal, "currency") }}</span
          >
        </div>

        <v-divider class="mt-3 mb-6" />
        <div
          class="text-body-2 d-flex justify-space-between align-center font-weight-bold text-uppercase"
        >
          <span>{{ $t("cartSummary.grossTotal") }}</span>
          {{ $n(calculatedTotal, "currency") }}
        </div>
      </v-card-text>
    </v-card>
    <div class="checkout-buttons mt-2 mb-4" v-if="!isCordova && isCheckout">
      <v-tooltip top open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="accent"
            class="white--text my-2"
            v-bind="attrs"
            v-on="on"
            block
            x-large
            depressed
            :disabled="
              profileLevel < minProfileLevel || orderCart.totalItems == 0
            "
            @click="fastPayment"
          >
            {{ $t("checkout.fastPaymentBtn") }}
          </v-btn> </template
        ><span v-html="$t('checkout.fastPaymentBtnTooltip')"
      /></v-tooltip>
      <v-btn
        color="primary"
        class="my-2"
        block
        x-large
        depressed
        :disabled="profileLevel < minProfileLevel || orderCart.totalItems == 0"
        :loading="loading"
        @click="goToPayment"
      >
        {{ $t("checkout.goToPaymentBtn") }}
      </v-btn>

      <!-- <i18n class="d-block text-caption mt-2" path="checkout.warning"></i18n> -->
      <AcceptRulesText />
    </div>
  </v-layout>
</template>

<script>
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CheckoutConfirmEmail from "@/components/cart/CheckoutConfirmEmail.vue";
import DeliveryFee from "@/components/delivery/DeliveryFee.vue";
import GiftCode from "@/components/gift/GiftCode.vue";
import AcceptRulesText from "@/components/payment/AcceptRulesText.vue";

import get from "lodash/get";

import { mapGetters } from "vuex";
export default {
  name: "CheckoutSummary",
  props: {
    orderCart: { type: Object, required: true },
    isCheckout: { type: Boolean, default: true },
    isOrderDetailPage: { type: Boolean, default: false },
    deliveryFee: {
      type: Object,
      default: () => {
        return {};
      }
    },
    giftCertificates: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GiftCode,
    DeliveryFee,
    CheckoutCompleteProfile,
    CheckoutConfirmEmail,
    AcceptRulesText
  },
  data() {
    return {
      minProfileLevel: global.config.minProfileLevel
    };
  },
  computed: {
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      getCartStatus: "cart/getCartStatus",
      deliveryServiceId: "cart/getDeliveryServiceId",
      isBetaTester: "cart/isBetaTester"
    }),
    confirmed() {
      return get(this.orderCart, "user.profile.confirmed", false);
    },
    netTotal() {
      return this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
    },
    calculatedTotal() {
      if (this.orderCart.grossTotal > this.orderCart.giftTotal) {
        return this.orderCart.grossTotal;
      }
      return this.orderCart.giftTotal;
    }
  },
  methods: {
    removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
    },
    addGiftCode(giftCodeId) {
      this.$emit("addGiftCode", giftCodeId);
    },
    sendGiftCode(giftCodeId) {
      this.$emit("sendGiftCode", giftCodeId);
    },
    fastPayment() {
      this.$emit("fastPayment");
    },
    goToPayment() {
      this.$emit("goToPayment");
    },
    giftAmount(gift) {
      let value = null;

      if (gift.amount && gift.amount > 0) {
        if (gift.name.indexOf("%") > -1) {
          value = gift.amount + " %";
        } else {
          value = this.$n(gift.amount, "currency");
        }
      } else if (gift.total && gift.total > 0) {
        value = this.$n(gift.total, "currency");
      }

      return value;
    }
  }
};
</script>
