<template>
  <v-card flat class="pa-3">
    <v-row class="d-flex flex-column">
      <v-col cols="12">
        <h3 class="secondary--text text-lighten-1 mb-3">
          {{ $t("cart.giftCode.title") }}
        </h3>

        <v-row no-gutters class="d-flex align-center justify-space-between">
          <v-col cols="8">
            <v-select
              :items="giftCertificatesReceived"
              v-model="giftCertificateId"
              hide-details="auto"
              :placeholder="$t('cart.giftCode.selectPlaceholder')"
              class="mr-2 text-caption secondary--text"
              item-text="name"
              item-value="userGiftCertificateId"
              dense
              outlined
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-btn
              color="secondary"
              elevation="0"
              block
              depressed
              v-on:click="addGiftCode(giftCertificateId)"
              :disabled="createDisabled || giftCertificateId === -1"
              >{{ $t("cart.giftCode.button.use") }}</v-btn
            >
          </v-col>
        </v-row>

        <div class="py-2 text-caption ml-1">{{ $t("cart.giftCode.or") }}</div>

        <v-row no-gutters class="d-flex align-center justify-space-between">
          <v-col cols="8">
            <v-text-field
              :placeholder="$t('cart.giftCode.inputPlaceholder')"
              hide-details="auto"
              v-model="giftCode"
              class="mr-2 text-caption secondary--text"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              color="secondary"
              elevation="0"
              depressed
              block
              v-on:click="sendGiftCode(giftCode)"
              :disabled="createDisabled || giftCode.length === 0"
              >{{ $t("cart.giftCode.button.apply") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped lang="scss">
::v-deep.v-select .v-select__selection--comma {
  color: var(--v-default-base) !important;
  font-weight: 400 !important;
}
::v-deep.v-select .v-input__append-inner i {
  color: $secondary !important;
}
</style>
<script>
export default {
  name: "GiftCode",
  props: { giftCertificatesReceived: { type: Array, required: true } },
  data() {
    return {
      giftCode: "",
      giftCertificateId: "",
      createDisabled: false
    };
  },
  methods: {
    addGiftCode(giftCertificateId) {
      this.$emit("addGiftCode", giftCertificateId);
    },
    sendGiftCode(giftCode) {
      this.$emit("sendGiftCode", giftCode);
    }
  },
  created() {
    this.giftCertificatesReceived.unshift({
      key: -1,
      name: "Nessun Buono Disponibile",
      userGiftCertificateId: -1
    });
    this.giftCertificateId = -1;
  }
};
</script>
