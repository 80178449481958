<template>
  <v-card
    flat
    @click="edit"
    class="cart-info-card d-flex justify-space-between align-center pa-3 py-sm-3 h-100"
  >
    <v-row no-gutters justify="space-between" align="center">
      <div class="d-flex flex-row align-center">
        <v-icon color="secondary cart-info-card-icon" class="mr-2">{{
          icon
        }}</v-icon>
        <div class="text-caption">
          <div>{{ text }}</div>
          <div class="font-weight-bold">{{ infoValue }}</div>
        </div>
      </div>
      <v-icon class="edit" small v-if="editable">
        $edit
      </v-icon>
    </v-row>
  </v-card>
</template>
<style scoped>
.cart-info-card-icon {
  font-size: 32px !important;
}
</style>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: true },
    text: { type: String, required: true },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
