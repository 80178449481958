<template>
  <CartInfoCard
    :infoValue="value"
    :text="text"
    icon="$storefront"
    :editable="editable"
    @edit="edit"
  />
</template>
<script>
import CartInfoCard from "./CartInfoCard.vue";
export default {
  name: "CartInfoAddressCard",
  props: {
    shippingAddress: { type: Object, required: true },
    editable: { type: Boolean, default: false }
  },
  components: { CartInfoCard },
  computed: {
    value() {
      return this.$t(
        "navbar.address." + this.shippingAddress.addressTypeId + ".format",
        this.shippingAddress
      );
    },
    text() {
      return this.$t(
        "navbar.address." +
          this.shippingAddress.addressTypeId +
          ".addressButtonTitle"
      );
    }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
